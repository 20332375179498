import { forwardRef, useEffect, useMemo, useState } from "react";

import useCombinedRefs from "../../use/combined-refs";
import useTextMeasure from "../../use/text-measure";
import { classNames } from "../../utils";

export type InputProps = JSX.IntrinsicElements["input"] & {
  prefix?: string;
  suffix?: string;
  className?: string;
  ignoreValueOnFocus?: boolean;
};

const Input = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  let { prefix, suffix, className, ignoreValueOnFocus, ...inputProps } = props;
  const stringValueProp = String(props.value ?? "");
  const inputRef = useCombinedRefs<HTMLInputElement>(ref);
  const [currentValue, setCurrentValue] = useState<string>(stringValueProp);
  const [hasFocus, setHasFocus] = useState(false);

  const prefixLength = useTextMeasure("text-sm", prefix);
  const valueLength = useTextMeasure("text-sm", suffix?.startsWith(" ") ? `${currentValue} ` : currentValue);

  useEffect(() => {
    if (!hasFocus || !ignoreValueOnFocus) setCurrentValue(stringValueProp);
  }, [stringValueProp, hasFocus, ignoreValueOnFocus]);

  useEffect(() => {
    const input = inputRef.current;

    function onInput() {
      setCurrentValue(input?.value || "");
    }

    setCurrentValue(input?.value || "");
    input?.addEventListener("input", onInput);

    return () => {
      input?.removeEventListener("input", onInput);
    };
  }, [inputRef]);

  useEffect(() => {
    if (hasFocus && inputProps.type === "number") {
      const listener = () => {
        inputRef.current?.blur();
      };

      document.addEventListener("wheel", listener);

      return () => {
        document.removeEventListener("wheel", listener);
      };
    }
  }, [inputRef, hasFocus, inputProps.type]);

  const paddingLeft = useMemo(() => {
    if (!prefix) return 16;

    return prefixLength + 16;
  }, [prefix, prefixLength]);

  return (
    <div className="relative flex rounded-md shadow-sm">
      {prefix && (
        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-4">
          <span className="text-sm text-gray-500">{prefix}</span>
        </div>
      )}
      <input
        ref={inputRef}
        className={classNames(
          `block grow border border-gray-200 bg-white p-2 px-4 text-sm transition duration-100 ease-in-out rounded-md
          disabled:cursor-not-allowed disabled:border-gray-300 disabled:bg-gray-50 dark:text-white dark:bg-gray-800
          focus:border-primary dark:focus:border-secondary focus:ring-primary dark:focus:ring-secondary focus:ring-1
          dark:border-gray-700 group-[.has-error]:border-red-300 group-[.has-error]:pr-10 group-[.has-error]:text-red-900
          group-[.has-error]:placeholder-red-300 group-[.has-error]:focus:border-red-500 group-[.has-error]:focus:outline-none
          group-[.has-error]:focus:ring-red-500 group-[.has-error]:dark:border-red-500 group-[.has-error]:dark:text-red-200
          group-[.has-error]:dark:focus:border-red-500`,
          className,
        )}
        style={{
          paddingLeft,
        }}
        onFocus={() => setHasFocus(true)}
        onBlur={() => setHasFocus(false)}
        {...inputProps}
        value={ignoreValueOnFocus && hasFocus ? currentValue : props.value}
      />
      {suffix && (
        <div
          className="pointer-events-none absolute inset-0 flex items-center overflow-hidden"
          style={{
            paddingLeft: `${paddingLeft + valueLength + 1}px`, // + 1 because it looks like shit otherwise. I don't know why.
          }}
        >
          <span className="text-sm text-gray-500">{suffix}</span>
        </div>
      )}
      {!suffix && (
        <div className="pointer-events-none absolute inset-y-0 right-0 hidden items-center pr-3 group-[.has-error]:flex">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            className="size-5 text-red-500"
          >
            <path
              fillRule="evenodd"
              d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zM12 8.25a.75.75 0 01.75.75v3.75a.75.75 0 01-1.5 0V9a.75.75 0 01.75-.75zm0 8.25a.75.75 0 100-1.5.75.75 0 000 1.5z"
              clipRule="evenodd"
            />
          </svg>
        </div>
      )}
    </div>
  );
});

export default Input;
