import {
  ArchiveBoxIcon,
  ChartBarSquareIcon,
  CheckCircleIcon,
  ClipboardDocumentIcon,
  CodeBracketSquareIcon,
  Cog6ToothIcon,
  CreditCardIcon,
  CurrencyDollarIcon,
  CursorArrowRaysIcon,
  DocumentCurrencyDollarIcon,
  FaceSmileIcon,
  FilmIcon,
  KeyIcon,
  PaperAirplaneIcon,
  PresentationChartLineIcon,
  PuzzlePieceIcon,
  QueueListIcon,
  RocketLaunchIcon,
  SparklesIcon,
  TrashIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";

import DataTypes from "~data";
import { TeamMembershipAuthStatusData } from "~services/app/endpoints/auth/statusShow/response";

import { LinkToParam } from "../../../components/ui/Link";
import { Product } from "../../../data/models/partials/Product";
import { Team } from "../../../data/models/Team";
import t from "../../../i18n";
import { useApiData } from "../../../use/api";

export type SidebarItem = {
  id:
    | "up.getting-started"
    | "hs.cobrowse"
    | "up.team"
    | "up.reporting.usage"
    | "up.visitorData"
    | "up.settings"
    | "up.billing"
    | "hq.root"
    | "hq.dashboards"
    | "hq.customerSuccess"
    | "hq.sales.opportunities"
    | "hq.sales.leads"
    | "hq.sales.tasks"
    | "hq.sales.quotes"
    | "hq.globalActivityLog"
    | "hq.activityLog"
    | "up.install"
    | "up.integrations"
    | "up.history"
    | "up.activityLog"
    | "ld.create"
    | "ld.links"
    | "ld.templates"
    | "co.dashboard";
  name: string;
  to: LinkToParam;
  icon: React.ComponentType<any>;
  current: boolean;
  count?: number;
  countColor?: "red";
};

type SecondarySidebarItem = {
  id: string;
  name: string;
  to: LinkToParam;
  current: boolean;
};

export function usePrimaryNavigation({
  isAdmin,
  teams,
  teamMemberships,
  currentRoute,
  currentProduct,
  currentTeamId,
  adminRoles,
}: {
  isAdmin: boolean;
  teams: Pick<Team, "id" | "domain" | "uniqueIdentifier" | "onboardingCompleted" | "products">[];
  teamMemberships: Array<
    TeamMembershipAuthStatusData & { team: Pick<Team, "id" | "uniqueIdentifier" | "onboardingCompleted" | "domain"> }
  >;
  currentRoute?: SidebarItem["id"];
  currentTeamId?: string;
  currentProduct?: Product;
  adminRoles: Exclude<DataTypes["Users"]["adminRoles"], null>;
}): SidebarItem[] {
  // Filter out teams that don't have the current product
  teamMemberships = currentProduct
    ? teamMemberships.filter((m) => teams.find((t) => t.id === m.team.id)!.products.includes(currentProduct))
    : teamMemberships;

  const navTeam = teams.find((t) => t.id === currentTeamId) || teams[0]!;

  const teamWhere = (
    permission: keyof TeamMembershipAuthStatusData["permissions"],
  ): Pick<Team, "id" | "domain" | "uniqueIdentifier" | "onboardingCompleted"> | undefined => {
    const teams = teamMemberships.filter((m) => m.permissions[permission]).map((m) => m.team);
    return teams.find((t) => t.id === navTeam.id) || teams[0];
  };
  const gettingStartedTeam = teamMemberships.filter((m) => !m.team.onboardingCompleted && m.isOwner)[0]?.team,
    canUseTeam = teamWhere("canUse"),
    canAccessSettingsTeam = teamWhere("canAccessSettings"),
    canManageUsersTeam = teamWhere("canManageUsers"),
    canManageBillingTeam = teamWhere("canManageBilling"),
    canManageContentTeam = teamWhere("canManageContent"),
    canViewReportingTeam = teamWhere("canViewReporting"),
    canPurchaseTeam = teamWhere("canPurchase"),
    inHq = location.hostname.startsWith("hq.");

  const showProductItems = (product: Product) =>
    currentProduct === product || (!currentProduct && teams.some((t) => t.products.includes(product)));

  const { total: tasksTotal, due: tasksDue } = useApiData("hq.sales.tasks.count", undefined, { enable: inHq });
  const { companies: leads } = useApiData(
    "hq.sales.leads.list",
    { everyone: !adminRoles.includes("sales_lead_target") },
    {
      enable: inHq && adminRoles.includes("sales_view"),
    },
  );
  const { opportunities } = useApiData("hq.sales.opportunities.index", undefined, {
    enable: inHq && adminRoles.includes("sales_view"),
  });

  const navigationItems: Array<Omit<SidebarItem, "current"> | false | undefined> = inHq
    ? [
        {
          id: "hq.root",
          name: t("Mission Control"),
          to: ["hq.root"] as LinkToParam,
          icon: RocketLaunchIcon,
        },
        {
          id: "hq.customerSuccess",
          name: t("Customer Success"),
          to: ["hq.customerSuccess.index"],
          icon: FaceSmileIcon,
        },
        {
          id: "hq.sales.opportunities",
          name: t("Opportunities"),
          to: ["hq.sales.index"],
          icon: CurrencyDollarIcon,
          count: opportunities?.filter((o) => o.followUpNeeded).length || undefined, // eslint-disable-line @typescript-eslint/strict-boolean-expressions
        },
        {
          id: "hq.sales.quotes",
          name: t("Quotes"),
          to: ["hq.quotes.index"],
          icon: DocumentCurrencyDollarIcon,
        },
        {
          id: "hq.sales.leads",
          name: t("New Leads"),
          to: ["hq.sales.leads"],
          icon: QueueListIcon,
          count: leads?.length || undefined, // eslint-disable-line @typescript-eslint/strict-boolean-expressions
        },
        {
          id: "hq.sales.tasks",
          name: t("Tasks"),
          to: ["hq.sales.tasks"],
          icon: CheckCircleIcon,
          count: tasksDue || tasksTotal || undefined, // eslint-disable-line @typescript-eslint/strict-boolean-expressions
          countColor: tasksDue ? "red" : undefined, // eslint-disable-line @typescript-eslint/strict-boolean-expressions
        },
        {
          id: "hq.dashboards",
          name: t("Dashboards"),
          to: "https://evidence.upscope.io",
          icon: PresentationChartLineIcon,
        },
        {
          id: "hq.globalActivityLog",
          name: t("Global Activity Log"),
          to: ["hq.globalActivityLog", {}],
          icon: ArchiveBoxIcon,
        },
      ]
    : [
        isAdmin && {
          id: "hq.root",
          name: t("Mission Control"),
          to: ["hq.root"] as LinkToParam,
          icon: RocketLaunchIcon,
        },

        gettingStartedTeam && {
          id: "up.getting-started",
          name: t("Getting started"),
          to: [
            "up.onboarding",
            {
              teamDomain: gettingStartedTeam.domain,
            },
          ] as LinkToParam,
          icon: SparklesIcon,
        },

        currentProduct === "livedocument" && {
          id: "ld.create",
          name: t("New LiveDocument"),
          to: ["ld.links.create", { teamDomain: navTeam.domain }] as LinkToParam,
          icon: PaperAirplaneIcon,
        },

        currentProduct === "livedocument" && {
          id: "ld.links",
          name: t("Links"),
          to: ["ld.links.list", { teamDomain: navTeam.domain }] as LinkToParam,
          icon: QueueListIcon,
        },

        currentProduct === "livedocument" && {
          id: "ld.templates",
          name: t("Templates"),
          to: ["ld.templates.index", { teamDomain: navTeam.domain }] as LinkToParam,
          icon: ClipboardDocumentIcon,
        },

        currentProduct === "helloscreen" &&
          canUseTeam && {
            id: "hs.cobrowse",
            name: t("Co-browse"),
            to: ["hs.visitorsList", {}] as LinkToParam,
            icon: CursorArrowRaysIcon,
          },

        !gettingStartedTeam &&
          canAccessSettingsTeam &&
          (["helloscreen", "userview", "cobrowsingapi"] as const).some((product) => showProductItems(product)) && {
            id: "up.install",
            name: t("Install"),
            to: [
              "up.onboarding.install",
              {
                teamDomain: canAccessSettingsTeam.domain,
                teamUniqueIdentifier: canAccessSettingsTeam.uniqueIdentifier,
              },
            ] as LinkToParam,
            icon: CodeBracketSquareIcon,
          },

        canAccessSettingsTeam &&
          currentProduct === "cobrowsingapi" && {
            id: "co.dashboard",
            name: t("REST API"),
            to: ["co.dashboard", { teamDomain: canAccessSettingsTeam.domain }],
            icon: KeyIcon,
          },

        canAccessSettingsTeam &&
          showProductItems("helloscreen") && {
            id: "up.integrations",
            name: t("Integrations"),
            to: ["up.integrations", { teamDomain: canAccessSettingsTeam.domain }],
            icon: PuzzlePieceIcon,
          },

        canViewReportingTeam &&
          (["helloscreen", "cobrowsingapi"] as const).some((product) => showProductItems(product)) && {
            id: "up.history",
            name: t("Co-browsing History"),
            to: ["up.history", { teamDomain: canViewReportingTeam.domain }],
            icon: FilmIcon,
          },
        canViewReportingTeam && {
          id: "up.activityLog",
          name: t("Activity Log"),
          to: ["up.activity.activityLog", { teamDomain: canViewReportingTeam.domain }],
          icon: ArchiveBoxIcon,
        },

        canManageUsersTeam && {
          id: "up.team",
          name: t("Team"),
          to: ["up.settings.team", { teamDomain: canManageUsersTeam.domain }],
          icon: UsersIcon,
        },

        canViewReportingTeam &&
          (["helloscreen", "cobrowsingapi"] as const).some((product) => showProductItems(product)) && {
            id: "up.reporting.usage",
            name: t("Usage"),
            to: ["up.reporting.usage", { teamDomain: canViewReportingTeam.domain }],
            icon: ChartBarSquareIcon,
          },

        canAccessSettingsTeam && {
          id: "up.settings",
          name: t("Settings"),
          to: ["up.settings.general", { teamDomain: canAccessSettingsTeam.domain }],
          icon: Cog6ToothIcon,
        },

        canManageBillingTeam && {
          id: "up.billing",
          name: t("Billing"),
          to: ["up.settings.billing", { teamDomain: canManageBillingTeam.domain }],
          icon: CreditCardIcon,
        },

        canManageContentTeam &&
          (["helloscreen", "userview", "cobrowsingapi"] as const).some((product) => showProductItems(product)) && {
            id: "up.visitorData",
            name: t("Delete Visitor Data"),
            to: ["up.visitorData", { teamDomain: canManageContentTeam.domain }],
            icon: TrashIcon,
          },
      ];

  return navigationItems.filter(Boolean).map((item) => {
    const sidebarItem = item as SidebarItem;
    sidebarItem.current = item.id === currentRoute;
    return sidebarItem;
  });
}

export function useSecondaryNavigation({
  primaryNavigation,
  teams,
  currentTeamId,
}: {
  primaryNavigation: SecondarySidebarItem[];
  teams: Array<Pick<Team, "id" | "domain" | "uniqueIdentifier">>;
  currentTeamId?: `t_${string}`;
}): SecondarySidebarItem[] | undefined {
  const currentNavigation = primaryNavigation.find((item) => item.current);
  if (
    !currentNavigation ||
    typeof currentNavigation.to[1] === "undefined" || // It's not a dynamic route
    !Array.isArray(currentNavigation.to) ||
    !("teamDomain" in currentNavigation.to[1])
  )
    return;

  if (teams.length < 2) return;

  return teams.map((team) => {
    const sidebarItem: SecondarySidebarItem = {
      id: team.id,
      name: t(team.domain),
      to: [
        currentNavigation.to[0],
        {
          teamDomain: team.domain,
          teamUniqueIdentifier: team.uniqueIdentifier,
        },
      ] as LinkToParam,
      current: team.id === currentTeamId,
    };
    return sidebarItem;
  });
}

export function getCurrentProduct({
  teams,
  currentTeamId,
  hostName,
}: {
  teams: Pick<Team, "products" | "id" | "onboardingCompleted">[];
  currentTeamId?: `t_${string}`;
  hostName: string;
}): Product | undefined {
  if (hostName.includes("hq.upscope")) return;

  if (hostName.includes("cobrowsingapi")) return "cobrowsingapi";

  if (hostName.includes("helloscreen")) return "helloscreen";

  if (hostName.includes("userview")) return "userview";

  if (hostName.includes("livedocument")) return "livedocument";

  const currentTeam = currentTeamId ? teams.find((t) => t.id === currentTeamId) : teams[0];

  if (!currentTeam!.onboardingCompleted || currentTeam!.products.length === 1) return currentTeam!.products[0];
}
